
<template>
  <div class="container my-5">
    
    <MDBCard text="white" bg="primary" class="col-md-12 my-5">
            <MDBCardBody text="center">
        <MDBCardTitle><h2>{{$t('Advantages')}}</h2> </MDBCardTitle>
        
      </MDBCardBody>
            
          </MDBCard>
    <MDBCardGroup>
   
          
   <MDBCard border-color="info" shadow="5" bg="white" class="mb-3" text="center" >

         
   
    <MDBCardBody text="primary">
      <MDBCardTitle>{{$t('Security0')}}</MDBCardTitle>
      <MDBCardText>
        {{$t('Secutity')}}
      </MDBCardText>
    </MDBCardBody>
  </MDBCard>



  <MDBCard border-color="success" shadow="5" bg="primary" class="mb-3" text="center" >
    
    <MDBCardBody text="white">
      <MDBCardTitle>{{$t('Managment0')}}</MDBCardTitle>
      <MDBCardText>
        {{$t('Managment')}}
      </MDBCardText>
    </MDBCardBody>
  </MDBCard>
    
      </MDBCardGroup>

      <MDBCardGroup class="my-5">    
   <MDBCard border-color="info" shadow="5" bg="white" class="mb-3" text="center" >
   
    <MDBCardBody text="primary">
      <MDBCardTitle>{{$t('Save0')}}</MDBCardTitle>
      <MDBCardText>
        {{$t('Save')}}
      </MDBCardText>
    </MDBCardBody>
  </MDBCard>
  <MDBCard border-color="info" shadow="5" bg="primary" class="mb-3" text="center" >
        
        <MDBCardBody text="white">
          <MDBCardTitle>{{$t('Development0')}}</MDBCardTitle>
          <MDBCardText>
            {{$t('Development')}}
          </MDBCardText>
        </MDBCardBody>
      </MDBCard>
  
      </MDBCardGroup>
  
      <MDBCardGroup>
        <MDBCard class="mb-3">
          <img src="../assets/netic3601.png" class="w-80" />
    <MDBCardBody>
      <MDBCardTitle>{{$t('BusinessComunications0')}}</MDBCardTitle>
      <MDBCardText>
        {{$t('Business0')}}<br> {{$t('Business1')}}<br> {{$t('Business2')}}<br>  {{$t('Business3')}}
      </MDBCardText>
    </MDBCardBody>
  </MDBCard>

  <MDBCard class="mb-3">
    <img src="../assets/netic3602.png" class="w-80" />
    <MDBCardBody>
      <MDBCardTitle>{{$t('Protect0')}}</MDBCardTitle>
      <MDBCardText>
        {{$t('Protect1')}}  
      </MDBCardText>
    </MDBCardBody>
  </MDBCard>

      </MDBCardGroup>
      <MDBCard text="white" bg="primary" class="col-md-12 my-5">
            <MDBCardBody text="center">
        <MDBCardTitle><h2>{{$t('Presentations')}}</h2> </MDBCardTitle>
        
      </MDBCardBody>
            
          </MDBCard>
      
      <div class="row">
            <div class="col">
                <div class="card">
                                    

                    </div>
                    <div class="ratio ratio-16x9">
                        
                        <video controls poster="../assets/poster.png">
          <source src="../assets/360presentation.mp4" type="video/mp4">
          
      </video>
                </div>
            </div>
            <div class="col">
                <div class="card">
                                    

                    </div>
                    <div class="ratio ratio-16x9">
                        
                        <video controls poster="../assets/poster.png">
          <source src="../assets/Netic360Presentation.mp4" type="video/mp4">
          
      </video>
                </div>
            </div>
        </div>

     
    </div>
</template>

<script>
  import { MDBCardGroup,MDBCard, MDBCardBody, MDBCardTitle, MDBCardText} from "mdb-vue-ui-kit";
  export default {
    components: {
    MDBCard,
      MDBCardBody,
      MDBCardTitle,
      MDBCardText,
      MDBCardGroup,
    
    }
  
  };
</script>


<style>

</style>



