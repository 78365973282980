<template>
    <div class="container my-3">
        <div class="row">
            <div class="col">
                <div class="card">
                                    

                    </div>
                    <div class="ratio ratio-16x9">
                        
                        <video controls poster="../assets/poster.png">
          <source src="../assets/Netic360.mp4" type="video/mp4">
          
      </video>
                </div>
            </div>
        </div>

        
        <section ref="about">
          <MDBCard text="white" bg="primary" class="col-md-12 my-3">
            <MDBCardBody text="center">
        <MDBCardTitle><h1>NETIC360</h1> </MDBCardTitle>
        
      </MDBCardBody>
            
          </MDBCard>
            <MDBCardGroup>
            <MDBCard text="white" bg="primary" class="col-md-6 my-3">
    <MDBCardHeader>Netic</MDBCardHeader>
      <MDBCardBody text="center">
        <MDBCardTitle>{{$t('About')}}</MDBCardTitle>
        <MDBCardText>
            {{$t('Netic')}}
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>
    <div class="bg-image hover-zoom col-md-6 my-3">
    <img src="../assets/Apple-DevicesV2.png" class="w-100" />
  </div>

</MDBCardGroup>
<MDBCardGroup>
    
        <div class="bg-image hover-zoom col-md-6 my-3">
    <img src="../assets/Apple-DevicesV2.png" class="w-100" />
  </div>
  
    <MDBCard text="grey" bg="white" class="col-md-6 my-3">
    <MDBCardHeader>Netic-360</MDBCardHeader>
      <MDBCardBody text="center">
        <MDBCardTitle>{{$t('About')}}</MDBCardTitle>
        <MDBCardText>
            {{$t('Netic360')}}
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>
</MDBCardGroup>


        
        </section>
    </div>
    </template>

<script>
  import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardHeader,  MDBCardGroup,} from "mdb-vue-ui-kit";
  export default {
    components: {
      MDBCard,
      MDBCardBody,
      MDBCardTitle,
      MDBCardText,
      MDBCardHeader,
      MDBCardGroup,

    }
  };
</script>