<template>
    

    <!--FOOTER-->
    <MDBFooter bg="primary" :text="['center', 'white']">
        <!-- Grid container -->
        <MDBContainer class="p-4 pb-0">
          <!-- Section: Social media -->
          <section class="mb-4">
            <!-- Facebook -->
            <MDBBtn
              tag="a"
              outline="light"
              href="https://www.facebook.com/neticinfo"
              floating
              class="m-1"
              target="_blank"
            >
              <MDBIcon iconStyle="fab" icon="facebook-f"></MDBIcon>
            </MDBBtn>
            
            <!-- Linkedin -->
            <MDBBtn
              tag="a"
              outline="light"
              href="https://www.linkedin.com/company/neticinfo/"
              floating
              class="m-1"
              target="_blank"
            >
              <MDBIcon iconStyle="fab" icon="linkedin-in"></MDBIcon>
            </MDBBtn>
            <!-- Github -->
            <MDBBtn
              tag="a"
              outline="light"
              href="https://neticgroup.github.io/"
              floating
              class="m-1"
              target="_blank"
            >
              <MDBIcon iconStyle="fab" icon="github"></MDBIcon>
            </MDBBtn>
            <!-- Twitter -->
            <MDBBtn
              tag="a"
              outline="light"
              href="https://neticgroup.github.io/"
              floating
              class="m-1"
              target="_blank"
            >
              <MDBIcon iconStyle="fab" icon="twitter"></MDBIcon>
            </MDBBtn>
    
            <!-- Instagram -->
            <MDBBtn
              tag="a"
              outline="light"
              href="https://neticgroup.github.io/"
              floating
              class="m-1"
              target="_blank"
            >
              <MDBIcon iconStyle="fab" icon="instagram"></MDBIcon>
            </MDBBtn>
    
            <!-- Whatsapp -->
            <MDBBtn
              tag="a"
              outline="light"
              href="https://neticgroup.github.io/"
              floating
              class="m-1"
              target="_blank"
            >
              <MDBIcon iconStyle="fab" icon="whatsapp"></MDBIcon>
            </MDBBtn>
    
          </section>
          <!-- Section: Social media -->
        </MDBContainer>
        <!-- Grid container -->
        <!-- Copyright -->
        <div
          class="text-center p-3"
          style="background-color: rgba(0, 0, 0, 0.2)"
        >
          © 2023 Copyright:
          <a class="text-white" href="https://netic.io" target="blank"
            >Netic360</a
          >
        </div>
        <!-- Copyright -->
      </MDBFooter>
    </template>
    

    <script>
    import { MDBFooter, MDBContainer, MDBIcon, mdbRipple } from 'mdb-vue-ui-kit';
  
    export default {
      components: {
        MDBFooter,
        MDBContainer,
       
      
        MDBIcon
      },
      directives: {
        mdbRipple
      }
    };
  </script>
     