<template>
    
  <!--Contacts-->
  <section id="contacts">
    

   
  <MDBContainer class="my-3">

    <MDBCard text="white" bg="primary" class="col-md-12 my-3">
            <MDBCardBody text="center">
        <MDBCardTitle><h2>{{$t('Contact')}}</h2> </MDBCardTitle>

        
      </MDBCardBody>
    </MDBCard>


  <MDBRow>
    <MDBCol md="6">             
          <form role="form" method="POST" @submit.prevent="sendEmail">
      <!-- Name input -->
      <MDBInput
        type="text"
        label="Name"
        id="form4Name"
        v-model="nameMsg"
        wrapperClass="mb-4"
      />
  
      <!-- Email input -->
      <MDBInput
        type="email"
        label="Email address"
        id="form4Email"
        v-model="emailMsg"
        wrapperClass="mb-4"
      />
  
      <!-- Message input -->
      <MDBTextarea
        label="Message"
        id="form4Textarea"
        v-model="messageMsg"
        wrapperClass="mb-4"
      />
  
      <!-- Checkbox -->
      <div class="form-check d-flex justify-content-center mb-4">
        <MDBCheckbox
          label="Send me a copy of this message"
          id="form4CopyCheck"
          v-model="form4CopyCheck"
        />
      </div>
  
      <!-- Submit button -->
      <MDBInput color="primary" block class="mb-4" type="submit" :disabled="!nameMsg || !emailMsg || !messageMsg"></MDBInput>
    </form>
  </MDBCol>
  
  <MDBCol md="6">
    <div class="text-center">
      <div class="text-center my-5" >
          <img
          src="../assets/Recurso-11-2048x628.png"
          height="80"
          alt=""
          loading="lazy"
        />
        </div>
        <div >
        <h4>Email: info@netic.io</h4>
        <h4>Phone: +34 986 955 861</h4>
      </div>
    </div>
  
  </MDBCol>
  </MDBRow>
  </MDBContainer>
  </section>
  </template>
  
  
  
  <script>
   import {
      MDBInput,
      MDBCheckbox,
      MDBCard,
      MDBTextarea,
      MDBCol,
      MDBRow,
      MDBContainer,
      MDBCardBody,
    } from "mdb-vue-ui-kit";
    
    import axios from 'axios';
  
    
  
    export default {
      components: {
        MDBInput,
        MDBCheckbox,
        MDBCard,
        MDBTextarea,
        MDBCol,
        MDBRow,
        MDBContainer,
        MDBCardBody,
      },
  data() {    
  return {      
        nameMsg: '',      
        emailMsg: '',      
        messageMsg: '',      
        loadingTxt: false,
        box:false,
        };  
  },
  methods: {    
  sendEmail() {      
  this.loadingTxt= true;  
  this.box = window.alert("Message sent successfully!") ;    
  axios.post('https://formspree.io/f/meqbjyey',
  {
     name: this.nameMsg,          
     from: this.emailMsg,          
     _subject: `${this.nameMsg} | Friendly Message from Github Page`,
     message: this.messageMsg,},
     
  ).then(() => {
     this.nameMsg = '';
     this.emailMsg = '';
     this.messageMsg = '';
     this.loadingTxt = false;     
  })
  },
  metaInfo() {
        return { 
            title: "Netic360",
            meta: [
                { name: 'Netic360', content:  'Netic360'},
                { property: 'og:title', content: "Netic360"},
                { property: 'og:site_name', content: 'Netic360'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'} 
            ]
        }
    }
  },
  };
  
  </script>