<template>
  <HelloWorld />
  <MainNetic />
  <NeticV />
  <ContactsC />
  <FooterN />  
  
</template>

<script>
import HelloWorld from './components/NavBar.vue'
import ContactsC from './components/ContactsC.vue'
import MainNetic from './components/MainNetic.vue';
import NeticV from './components/NeticV.vue';
import FooterN from './components/FooterN.vue';


export default {
  name: 'App',
  components: {
    HelloWorld,
    ContactsC,
    MainNetic,
    NeticV,
    FooterN
}
}
</script>

<style>
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}
</style>
