<template>
  <MDBNavbar  expand="lg" light  container="md">
    
    <MDBNavbarToggler
      @click="collapse1 = !collapse1"
      target="#navbarSupportedContent"
    ></MDBNavbarToggler>
    <MDBCollapse v-model="collapse1" id="navbarSupportedContent">
      <MDBNavbarNav class="mb-2 mb-lg-0">
        <MDBNavbarItem target="_blank" href="https://netic.io" >
          Netic
        </MDBNavbarItem>
        <MDBNavbarItem target="_blank" href="https://neticgroup.github.io" >
          Netic Group
        </MDBNavbarItem>
        <MDBNavbarItem target="_blank" href="https://triskelus.com" >
          Triskelus
        </MDBNavbarItem>
        <MDBNavbarItem>
       
        </MDBNavbarItem>
      </MDBNavbarNav>
      <!-- Lang form -->
       <!-- Icon dropdown -->
      <div class="locale-changer">
        <select  class="nav-item" v-model="$i18n.locale">
          <option flag="united-kingdom" class="m-0" value="en"><i class="flag-poland flag"></i>English</option>
          <option value="es">Español</option>
          <option value="pt">Português</option>
        </select>
     </div>
      <!-- Lang form -->
       <!-- Icon dropdown -->
    </MDBCollapse>
  </MDBNavbar>
</template>

<script>
import {
    
    MDBNavbar,
    MDBNavbarToggler,
    
    MDBNavbarNav,
    MDBNavbarItem,
    MDBCollapse,
    
    
    
   
  } from 'mdb-vue-ui-kit';
  import { ref } from 'vue';
  
  export default {
    components: {
      
    MDBNavbar,
    MDBNavbarToggler,
    
    MDBNavbarNav,
    MDBNavbarItem,
    MDBCollapse,
    
    },
    setup() {
      const collapse1 = ref(false);
      const dropdown1 = ref(false);
      const dropdown5 = ref(false);
     
      return {
        collapse1,
        dropdown1,
        dropdown5
      }
    }
  };
  
  
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->


